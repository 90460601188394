import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Side Nav is a vertical list of navigational links, typically used on the left side of a page. For maximum flexibility, `}<strong parentName="p">{`SideNav elements have no default width or positioning.`}</strong></p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<SideNav bordered maxWidth={360} aria-label="Main">
  <SideNav.Link href="#account">
    <Text>Account</Text>
  </SideNav.Link>
  <SideNav.Link href="#home" selected>
    <Text>Profile</Text>
  </SideNav.Link>
  <SideNav.Link href="#emails">
    <Text>Emails</Text>
  </SideNav.Link>
  <SideNav.Link href="#notifications">
    <Text>Notifications</Text>
  </SideNav.Link>
</SideNav>
`}</code></pre>
    <p>{`Different kinds of content can be added inside a SideNav item. Use system props to further style them if needed.`}</p>
    <h2>{`Full variant`}</h2>
    <p>{`Add the `}<inlineCode parentName="p">{`variant='full'`}</inlineCode>{` prop to a `}<inlineCode parentName="p">{`SideNav.Link`}</inlineCode>{` to spread child elements across the link, which is useful for status icons, labels, and the like.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<SideNav bordered maxWidth={360} aria-label="Main">
  <SideNav.Link href="#url">
    <Text>Text Only</Text>
  </SideNav.Link>
  <SideNav.Link href="#url">
    <Avatar
      size={16}
      mr={2}
      src="https://avatars.githubusercontent.com/hubot?s=32"
    />
    <Text>With an avatar</Text>
  </SideNav.Link>
  <SideNav.Link href="#url">
    <StyledOcticon mr={2} size={16} icon={Zap} />
    <Text>With an Octicon</Text>
  </SideNav.Link>
  <SideNav.Link href="#url" variant="full" selected>
    <Text>With a status icon</Text>
    <StyledOcticon mr={2} size={16} icon={PrimitiveDot} color="green.5" />
  </SideNav.Link>
  <SideNav.Link href="#url" variant="full">
    <Text>With a label</Text>
    <Label bg='blue.5'>label</Label>
  </SideNav.Link>
  <SideNav.Link href="#url" variant="full">
    <Text>With a counter</Text>
    <CounterLabel>16</CounterLabel>
  </SideNav.Link>
  <SideNav.Link href="#url">
    <Heading as="h5" fontSize={1}>A heading</Heading>
    <Text>and some more content</Text>
  </SideNav.Link>
</SideNav>
`}</code></pre>
    <h2>{`Lightweight variant`}</h2>
    <p>{`Add the `}<inlineCode parentName="p">{`variant="lightweight"`}</inlineCode>{` prop to `}<inlineCode parentName="p">{`SideNav`}</inlineCode>{` to render an alternative, more lightweight version that has items with no borders and are more condensed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<BorderBox p={3} backgroundColor='gray.0' maxWidth={360}>
  <BorderBox borderWidth={0} borderBottomWidth={1} borderRadius={0} mb={2} pb={1}>
    <Heading as="h5" fontSize={1} color="gray.7">Menu</Heading>
  </BorderBox>
  <SideNav variant="lightweight">
    <SideNav.Link href="#url">
      <Text>Account</Text>
    </SideNav.Link>
    <SideNav.Link href="#url" selected>
      <Text>Profile</Text>
    </SideNav.Link>
    <SideNav.Link href="#url">
      <Text>Emails</Text>
    </SideNav.Link>
    <SideNav.Link href="#url">
      <Text>Notifications</Text>
    </SideNav.Link>
  </SideNav>
</BorderBox>
`}</code></pre>
    <p>{`It can also appear nested, as a sub navigation. Use margin/padding `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` to add indentation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<SideNav bordered maxWidth={360}>
  <SideNav.Link href="#url">
    <StyledOcticon mr={2} size={16} icon={Person} />
    <Text>Account</Text>
  </SideNav.Link>
  <SideNav.Link href="#url" selected>
    <StyledOcticon mr={2} size={16} icon={Octoface} />
    <Text>Profile</Text>
  </SideNav.Link>

  <SideNav bordered variant="lightweight" py={3} pl={6} backgroundColor="white">
    <SideNav.Link href="#url" selected>
      <Text>Sub item 1</Text>
    </SideNav.Link>
    <SideNav.Link href="#url">
      <Text>Sub item 2</Text>
    </SideNav.Link>
    <SideNav.Link href="#url">
      <Text>Sub item 3</Text>
    </SideNav.Link>
  </SideNav>

  <SideNav.Link href="#url">
    <StyledOcticon mr={2} size={16} icon={Mail} />
    <Text>Emails</Text>
  </SideNav.Link>
</SideNav>
`}</code></pre>
    <h2>{`Usage with React Router`}</h2>
    <p>{`If using React Router, you can use the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop to render the element as a `}<inlineCode parentName="p">{`NavLink`}</inlineCode>{`. React Router will automatically handle setting `}<inlineCode parentName="p">{`aria-current="page"`}</inlineCode>{` for you.`}</p>
    <pre><code parentName="pre" {...{}}>{`<SideNav.Link as={NavLink} to="...">...</SideNav.Link>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p><inlineCode parentName="p">{`SideNav`}</inlineCode>{` components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{`, `}<inlineCode parentName="p">{`BORDER`}</inlineCode>{`, `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{`, and `}<inlineCode parentName="p">{`FLEX`}</inlineCode>{` system props. `}<inlineCode parentName="p">{`SideNav.Link`}</inlineCode>{` components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` and `}<inlineCode parentName="p">{`TYPOGRAPHY`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <h3>{`SideNav`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'nav'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the HTML tag for the component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`bordered`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Renders the component with a border.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'normal'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set to `}<inlineCode parentName="td">{`lightweight`}</inlineCode>{` to render `}<a parentName="td" {...{
              "href": "#lightweight-variant"
            }}>{`in a lightweight style`}</a>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`SideNav.Link`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'a'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the HTML tag for the component.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`URL to be used for the Link`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`muted`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Uses light gray for Link color, and blue on hover`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`selected`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the link as selected, giving it a different style and setting the `}<inlineCode parentName="td">{`aria-current`}</inlineCode>{` attribute.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`underline`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Adds underline to the Link`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'normal'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Set to `}<inlineCode parentName="td">{`full`}</inlineCode>{` to render `}<a parentName="td" {...{
              "href": "#full-variant"
            }}>{`a full variant`}</a>{`, suitable for including icons and labels.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      